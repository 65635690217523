.qr-container {
  margin-left: 100px;
  display: none;
  flex-direction: row;
  z-index: 1;
}

.qr-image-container {
  position: relative;
  top: 0;
  left: 0;
}

.blue-rectangle {
  height: 200px;
  width: 200px;
  position: relative;
  top: 0;
  left: 0;
  height: 100px;
  width: 100px;
}

.qr-image {
  border-radius: 10px;
  height: 100px;
  width: 100px;
  margin-top: 5px;
  border-width: 5px;
  border-color:  #1A76CB;
  border-style: solid;
}

.scan-instruction-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.scan-text {
  padding-left: 10px;
  padding-right: 20px;
  font-weight: 700;
  font-size: 20px;
  color: white;
  line-height: 25px;
  margin-bottom: 20px;
}

.qr-line {
  position:absolute;
  width: 50rem;
  left: 0;
  margin-top: -10px;
  height: 6rem;
}


@media (min-width: 800px) {
  .qr-container{
    display: flex;
  }
}