.qr-container-lp1 {
    display: none;
    margin-left: 150px;
    flex-direction: row;
  }
  
  .qr-container-lp1 {
    margin-left: 100px;
  }
  
  .qr-image-container-lp1 {
    position: relative;
    top: 0;
    left: 0;
  }
  
  .blue-rectangle-lp1 {
    height: 200px;
    width: 200px;
    position: relative;
    top: 0;
    left: 0;
    height: 100px;
    width: 100px;
    z-index: 1;
  }
  
  .qr-image-lp1 {
    height: 190px;
    width: 190px;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    height: 100px;
    width: 100px;
    z-index: 1;
  }
  
  .scan-instruction-container-lp1 {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  
  .scan-text-lp1 {
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 300;
    font-size: 30px;
    color: white;
    margin-top: 10px;
  }
  
  .qr-line-lp1 {
    margin-top: 10px;
  }
  
  
  @media (min-width: 800px) {
    .qr-container-lp1{
      display: flex;
    }
}