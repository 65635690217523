.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #21232b;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  scrollbar-color: transparent;
}

.lp6-main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  scrollbar-color: transparent;
}

.lp6-new-main-container {
  background-color: #0B1124;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  scrollbar-color: transparent;
}

.main-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.container,
.container-lp1 {
  background-image: url("../assets/images/mobile-BG.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 2%;
}

.container-lp6 {
  background-image: url("../assets/images/mobile-BG.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container-lp5 {
  background-image: url("../assets/images/mobile-BG-lp5.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 2%;
}

.container-lp3 {
  background-image: url("../assets/images/new-mobile-BG.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 2%;
}

.middle-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  background-size: contain;
  padding-top: 2%;
}

.sub-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.footer-container {
  background: linear-gradient(180deg, #202328 0%, #000000 100%);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-bottom: 10%;
}

.lp6-footer-container {
  background-color: #F8F8F8;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-bottom: 28%;
}

.lp6-new-footer-container {
  background-color: #0B1124;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-bottom: 34%;
}

.download-link {
  text-decoration: none;
}

.nav-button {
  align-items: center;
  color: rgba(168, 211, 255, 1);
  display: flex;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  justify-content: center;
  text-align: center;
  text-decoration: none;
}

.copyright-company {
  font-weight: 700;
}

.title-and-button,
.title-and-button-lp1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
}

.title-and-button-lp1 {
  gap: 5px;
}

.logos {
  height: 20%;
  object-fit: contain;
  width: 60vw;
  margin-top: 0.5rem;
}

.logos-lp1 {
  width: auto;
  height: 4.5rem;
  margin-top: 1rem;
  /* margin-bottom: 0.5rem; */
}

.nav-link {
  color: rgba(168, 211, 255, 1);
  text-decoration: none;
  font-size: 1.5rem;
  line-height: 2rem;
}

.lp6-nav-link {
  color: #165AA1;
  text-decoration: none;
  font-size: 1.5rem;
  line-height: 2rem;
}

.lp6-nav-link-new {
  color: #6274AD;
  text-decoration: none;
  font-size: 1.5rem;
  line-height: 2rem;
}

.title,
.mobile-title-lp1,
.desktop-title-lp1 {
  color: white;
  line-height: 30%;
  text-align: center;
}

.mobile-title-lp1 {
  line-height: 10%;
}

.desktop-title-lp1 {
  display: none;
}

.download-button-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  bottom: -0.1rem;
  z-index: 1;
}

.download-button-container-lp6 {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  bottom: -0.1rem;
  z-index: 1;
  background-color: white;
}

.download-button-container-lp6-new {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  bottom: -0.1rem;
  z-index: 1;
  background-color: #005C31;
}

.blur-download-button-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6.5rem;
  bottom: -0.1rem;
  z-index: 1;
  background-color: black;
  /* backdrop-filter: blur(8px); */
}

.download-button {
  border-radius: 5px;
  align-items: center;
  background: #23b513;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem 1rem 2rem;
}

.new-download-button {
  border-radius: 5px;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem 1rem 2rem;
}

.download-button-lp1 {
  align-items: center;
  background-color: #187fe0;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 0px 10px 0px 0px;
}

.download-icon-container {
  background-color: #1467b5;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  padding: 10px;
  margin-right: 10px;
}

.download-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.new-download-icon {
  height: 27px;
  width: 24px;
  margin-right: 6px;
}

.download-text {
  color: white;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 1rem;
  text-align: center;
}

.new-download-text {
  color: #005C31;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 17px;
  text-align: center;
}

.copyright,
.copyright-lp1 {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 0.6rem;
  justify-content: center;
  margin-bottom: 59px;
  text-align: center;
}

.qrCode {
  display: none;
}

.top-text,
.bottom-text-lp1 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.2rem;
  margin-top: 10px;
}

.margin-top-lp3 {
  margin-top: 10px;
}

.margin-bottom-lp3 {
  margin-bottom: 10px;
}

.top-text-lp3 {
  color: black;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  margin-left: 10px;
  margin-right: 10px;
}

.bottom-text-small {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.top-text-small {
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
}

.bottom-text,
.top-text-lp1 {
  /* margin-top: 4px; */
  margin-bottom: 16px;
  font-size: 1.0rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.bottom-text-lp3 {
  color: black;
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 1.0rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.top-text-lp1 {
  line-height: 25px;
}

.footer-nav,
.footer-nav-lp1 {
  margin-top: 50px;
}

.desktop-players,
.desktop-players-lp1,
.desktop-players-lp5 {
  display: none;
}



/* .qr-line {
  display: none;
} */

.mobile-players {
  display: flex;
  width: 98vw;
  height: 26rem;
  /* padding-top: 12px; */
  /* margin-bottom: 20px; */
}

.mobile-players-l6 {
  display: flex;
  width: 100vw;
  height: 36rem;
  /* padding-top: 12px; */
  /* margin-bottom: 20px; */
}

.bottom-mobile-text {
  display: flex;
  font-weight: 400;
  color: white;
  padding-bottom: 10px;
}

.bottom-mobile-text-lp6 {
  display: flex;
  font-size: 16;
  font-weight: 500;
  color: #302B28;
  padding-bottom: 10px;
}

.bottom-mobile-text-lp6-new {
  display: flex;
  font-size: 16;
  font-weight: 500;
  color: #ffffff;
  padding-bottom: 10px;
}

.mobile-players-lp1 {
  display: flex;
  width: 80vw;
  height: 22rem;
}

.mobile-players-lp5 {
  display: flex;
  width: 100vw;
  height: 22rem;
}

.mobile-landscape {
  display: none;
}

.section-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100vw;
}

.section-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 2rem;
}

.section-title-text {
  color: #3bb4f0;
  flex-wrap: wrap;
  /* white-space: pre-line; */
  font-family: "Inter";
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
}

.section-description-text {
  color: white;
  flex-wrap: wrap;
  font-family: "Inter";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3rem;
  margin-top: 0.5rem;
  /* white-space: pre-line; */
  text-align: center;
}

.section-image {
  display: flex;
  width: 100vw;
  height: 20rem;
  object-fit: contain;
  margin-top: 1.5rem;
}

.section-mobile-image {
  width: 100vw;
  height: 40rem;
  object-fit: contain;
}

.section-mobile-image-trophy {
  width: 100vw;
  height: 20rem;
  object-fit: contain;
}

.section-line {
  width: 100vw;
  align-self: flex-end;
  rotate: 180deg;
}


.desktop-empty-container {
  display: none;
}

.desktop-section2-container {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  margin-top: 1rem;
}

.section2-line {
  width: 100vw;
  align-self: flex-start;
}

.desktop-section4-container {
  display: flex;
  background-image: url("../assets/images/section4-background.webp");
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 100% 100%;
  justify-content: center;
  padding-bottom: 2rem;
  justify-content: center;
  align-items: center;
}

.desktop-section4-new-container {
  display: flex;
  background-image: url("../assets/images/winners-mobile-view.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 100% 100%;
  width: 100%;
  height: calc(100vw * 3.46);
}

.customer-review-container {
  display: flex;
  background-image: url("../assets/images/section5-background.webp");
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 100% 100%;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
  align-items: center;
}

.customer-review-title {
  color: white;
  flex-wrap: wrap;
  white-space: pre-line;
  font-family: "Inter";
  font-weight: 800;
  font-size: 1.8rem;
  line-height: 2rem;
  text-align: center;
}

.customer-review-subtitle {
  color: white;
  flex-wrap: wrap;
  white-space: pre-line;
  font-family: "Inter";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}

.section4-title-text {
  color: white;
  flex-wrap: wrap;
  white-space: pre-line;
  font-family: "Inter";
  font-weight: 800;
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
  margin-top: 3.5rem;
}

.section4-description-text {
  color: white;
  flex-wrap: wrap;
  font-family: "Inter";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  margin-top: 0.1rem;
}

.comments-container {
  display: grid;
  grid-template-columns: repeat(2, 10rem);
  justify-content: center;
  gap: 20px;
  padding-left: 0.8rem;
}

.social-container {
  display: flex;
  flex-direction: column;
}

.trusted-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.6rem;
}

.trusted-image {
  width: 80%;
  margin-top: 3rem;
}

.win-daily-image {
  width: 100vw;
}

.desktop-trusted-image {
  display: none;
}

.desktop-aigf-image {
  display: none;
}

.trusted-partner-text {
  color: #3bb4f0;
  flex-wrap: wrap;
  white-space: pre-line;
  font-family: "Inter";
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.5rem;
  text-align: center;
}

.follow-partner-text {
  color: #3bb4f0;
  flex-wrap: wrap;
  white-space: pre-line;
  font-family: "Inter";
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.5rem;
  text-align: center;
  margin-top: 2rem;
}

.trusted-divider {
  width: "100%";
  height: 0.5rem;
  background: linear-gradient(180deg,
      rgba(31, 33, 44, 0) 0%,
      #304c91 34.9%,
      #48767c 75.52%,
      rgba(31, 33, 44, 0) 98.44%);
}

.icon-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 2rem;
  margin-top: 2rem;
}

.facebook-icon {
  height: 2rem;
  width: 1rem;
}

.instagram-icon {
  height: 2rem;
  width: 2rem;
}

.linkedin-icon {
  width: 2rem;
  height: 2rem;
}

.twitter-icon {
  width: 2.5rem;
  height: 2rem;
}

.youtube-icon {
  width: 3rem;
  height: 2rem;
}

.mobile-support {
  color: white;
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 1rem;
  text-decoration: none;
}

.support-link {
  text-decoration: none;
  color: white;
  font-weight: 800;
}

.desktop-support {
  display: none;
}

.special-text {
  font-size: 18px;
  font-weight: 400;
  padding: 30px;
  color: black;
}

.special-container {
  background-color: white;
  padding-top: 0;
}

.special-sub-container {
  justify-content: space-between;
}

.special-button {
  width: 90vw;
  height: auto;
}

.special-sub-container {
  padding-left: 0;
  padding-right: 0;
}

.special-image {
  width: 100%;
  height: auto;
}

@media (min-width: 300px) {
  .mobile-players-lp1,
  .mobile-players-lp5 {
    height: auto;
  }

  .special-image {
    width: 100%;
    height: auto;
  }
}

@media (max-height: 400px) and (max-width: 800px) and (orientation: landscape) {
  .mobile-landscape {
    display: flex;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: white;
    justify-content: center;
    align-items: center;
  }

  .text-style {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.5rem;
  }

  .rotate-icon-style {
    height: 3rem;
    width: 3rem;
    margin-left: 1rem;
    rotate: 45deg;
    animation: rotate 2s infinite;
  }

  .main-container {
    display: none;
  }

  @keyframes rotate {
    0% {
      transform: rotate(-45deg);
    }

    100% {
      transform: rotate(45deg);
    }
  }
}

@media (min-width: 800px) {
  .special-image {
    width: 100%;
    height: auto;
  }

  .main-container {
    display: flex;
    width: 100vw;
  }

  .container,
  .container-lp1,
  .container-lp3 {
    background-image: url("../assets/images/desktop-BG.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* justify-content: space-evenly; */
    padding-bottom: 0;
    padding-top: 3rem;
    flex-direction: row;
    align-items: flex-end;
  }

  .container-lp5 {
    background-image: url("../assets/images/desktop-BTL-BG.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* justify-content: space-evenly; */
    padding-bottom: 0;
    padding-top: 3rem;
    flex-direction: row;
    align-items: flex-end;
  }

  .container-lp1,
  .container-lp3,
  .container-lp5 {
    justify-content: space-between;
    padding: 0;
  }

  .mobile-landscape {
    display: none;
  }

  /* .qr-line {
    width: 50vw;
    align-self: flex-end;
    rotate: 180deg;
    height: 6rem;
    z-index: 1;
  } */

  .footer-container {
    align-items: center;
    width: 100%;
    flex-direction: row;
    gap: 10px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    justify-content: space-between;
  }

  .footer-nav,
  .footer-nav-lp1 {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 75px;
    margin-left: 7rem;
    margin-top: 0px;
  }

  .footer-nav-lp1 {
    margin-left: 100px;
  }

  .nav-link {
    font-size: 27px;
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
  }

  .lp6-nav-link {
    color: #165AA1;
    font-size: 27px;
    font-weight: 300;
  }

  .lp6-nav-link-new {
    color: #6274AD;
    font-size: 27px;
    font-weight: 300;
  }

  .copyright,
  .copyright-lp1 {
    align-items: center;
    font-size: 1rem;
    margin-bottom: 0px;
    margin-right: 7rem;
  }

  .copyright-lp1 {
    margin-right: 100px;
  }

  .logos {
    height: auto;
    margin-left: 60px;
    max-width: 400px;
  }

  .logos-lp1 {
    margin-left: 100px;
    width: 484px;
    height: auto;
  }

  .download-button {
    display: none;
  }

  .new-download-button {
    display: none;
  }

  .download-button-container {
    display: none;
  }

  .qrCode {
    display: flex;
    height: 188px;
    margin-left: 150px;
    width: 492px;
  }

  .top-text,
  .bottom-text-lp1 {
    font-size: 75px;
    font-weight: 700;
    line-height: 81px;
    width: 70%;
  }

  .bottom-text-lp1 {
    font-size: 84px;
    line-height: 110px;
    width: 89%;
  }

  .bottom-text,
  .top-text-lp1 {
    font-size: 42px;
    font-weight: 500;
    line-height: 60px;
  }

  .top-text-lp1 {
    font-size: 31px;
    font-weight: 400;
    line-height: 45px;
  }

  .title,
  .desktop-title-lp1 {
    text-align: left;
  }

  .desktop-title-lp1 {
    display: flex;
    flex-direction: column;
  }

  .title-and-button,
  .title-and-button-lp1 {
    gap: 0px;
    margin-left: 60px;
    margin-top: 6rem;
  }

  .title-and-button-lp1 {
    margin-left: 100px;
    align-items: initial;
  }

  .border {
    border-color: white;
    border-style: solid;
    border-width: 1px;
    height: 4vh;
  }

  .qr-container,
  .qr-container-lp1 {
    margin-top: 4rem;
  }

  .desktop-players {
    max-width: 700px;
    display: flex;
    width: 65%;
    height: auto;
    margin-bottom: 150px;
  }

  .desktop-players-lp1 {
    display: flex;
    width: 40rem;
    height: 45rem;
  }

  .desktop-players-lp5 {
    display: flex;
    width: 60rem;
    height: 45rem;
  }

  .sub-container {
    margin-bottom: 5rem;
    flex: unset;
    padding-bottom: 0px;
    align-items: unset;
  }

  .mobile-players {
    display: none;
  }

  .mobile-players-l6 {
    display: none;
    /* padding-top: 12px; */
    /* margin-bottom: 20px; */
  }

  .bottom-mobile-text {
    display: none;
  }

  .bottom-mobile-text-lp6 {
    display: none;
  }

  .bottom-mobile-text-lp6-new {
    display: none;
  }

  .nav-link {
    color: rgba(255, 255, 255, 1);
    font-size: 1.2rem;
  }

  .lp6-nav-link {
    color: #165AA1;
    font-size: 1.2rem;
  }

  .lp6-nav-link-new {
    color: #6274AD;
    font-size: 1.2rem;
  }

  .mobile-title-lp1,
  .mobile-players-lp1,
  .mobile-players-lp5,
  .download-button-lp1 {
    display: none;
  }

  .blur-download-button-container {
    display: none;
  }

  .section-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .section-line {
    display: none;
  }

  .section-image {
    display: flex;
    width: 40rem;
    height: 30rem;
    object-fit: contain;
  }

  .section-mobile-image {
    display: none;
  }

  .section-mobile-image-trophy {
    display: none;
  }

  .section-sub-container {
    padding-left: 5rem;
    flex: 1;
  }

  .desktop-section2-container {
    display: flex;
    background-image: url("../assets/images/section3.webp");
    flex-direction: row;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .desktop-empty-container {
    display: flex;
    width: 60rem;
    flex: 1;
  }

  .desktop-section4-container {
    display: flex;
    background-image: url("../assets/images/section4-background.webp");
    flex-direction: row;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 100% 100%;
    justify-content: space-evenly;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-right: 25%;
    padding-left: 25%;
  }

  .desktop-section4-new-container {
    background-image: url("../assets/images/winners-desktop-view.webp");
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 100% 100%;
    width: 100%;
    height: calc(100vw * 0.8);
  }

  .section-title-text {
    color: #3bb4f0;
    flex-wrap: wrap;
    white-space: pre-line;
    font-family: "Inter";
    font-weight: 700;
    font-size: 2rem;
    line-height: 3rem;
  }

  .section-description-text {
    color: white;
    flex-wrap: wrap;
    font-family: "Inter";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1rem;
    white-space: pre-line;
  }

  .section4-title-text {
    color: white;
    flex-wrap: wrap;
    font-family: "Inter";
    font-weight: 700;
    font-size: 2rem;
    line-height: 3rem;
    text-align: center;
    margin-top: unset;
  }

  .section4-description-text {
    color: white;
    flex-wrap: wrap;
    font-family: "Inter";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: unset;
  }

  .section2-line {
    display: none;
  }

  .customer-review-title {
    font-size: 2.5rem;
    line-height: 2rem;
  }

  .customer-review-subtitle {
    font-size: 1.5rem;
    line-height: 1rem;
    margin-top: 1.5rem;
  }

  .comments-container {
    display: grid;
    grid-template-columns: repeat(2, 30rem);
    justify-content: center;
    padding-left: 5rem;
  }

  .trusted-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 1rem;
  }

  .trusted-image {
    display: none;
  }

  .win-daily-image {
    display: none;
  }

  .desktop-trusted-image {
    display: flex;
    width: 60%;
    margin-top: 2rem;
  }

  .desktop-aigf-image {
    display: flex;
    margin-top: 2rem;
    margin-right: 2rem;
  }

  .trusted-partner-text {
    display: none;
  }

  .follow-partner-text {
    color: white;
    flex-wrap: wrap;
    white-space: pre-line;
    font-family: "Inter";
    font-weight: 300;
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: center;
  }

  .social-container {
    flex-direction: row;
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .trusted-divider {
    width: "100%";
    height: 0.8rem;
    background: linear-gradient(180deg,
        rgba(31, 33, 44, 0) 0%,
        #304c91 34.9%,
        #48767c 75.52%,
        rgba(31, 33, 44, 0) 98.44%);
  }

  .icon-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 2rem;
    margin-top: 2rem;
  }

  .facebook-icon {
    height: 1.8rem;
    width: 1rem;
  }

  .instagram-icon {
    height: 1.8rem;
    width: 1.8rem;
  }

  .linkedin-icon {
    width: 1.8rem;
    height: 1.8rem;
  }

  .twitter-icon {
    width: 2.3rem;
    height: 1.8rem;
  }

  .youtube-icon {
    width: 2.5rem;
    height: 1.8rem;
  }

  .mobile-support {
    display: none;
  }

  .desktop-support {
    display: block;
    font-size: 1rem;
    color: white;
    margin-top: 1rem;
  }
}

@media (min-width: 1500px) {
  .container-lp1,
  .container-lp3,
  .container-lp5 {
    align-items: center;
    justify-content: space-evenly;
  }

  .desktop-players-lp1 {
    width: 52rem;
    height: 61rem;
  }

  .top-text-lp1 {
    font-size: 40px;
    font-weight: 400;
    line-height: 59px;
  }

  .bottom-text-lp1 {
    font-size: 105px;
    line-height: 126px;
    width: 100%;
  }

  .logos-lp1 {
    height: auto;
    width: 550px;
  }

  .footer-nav-lp1 {
    margin-left: 250px;
  }

  .copyright-lp1 {
    margin-right: 250px;
  }
}